<template>
  <div>
    <cabecalho-pagina
      :titulo="tituloCabecalho"
      :total-registros="tabela.quantidadeItens"
      sem-botao-ok
      sem-filtro
    />

    <div class="d-flex">
      <botao-padrao
        v-if="
          tabela.selecionados.length && buscarPermissao('Incerteza', 'Deletar')
        "
        color="danger"
        outlined
        class="mt-6"
        @click="excluirItem"
      >
        <v-icon>$mdiTrashCanOutline</v-icon>
        {{ $t('geral.botoes.excluir') }}
      </botao-padrao>
    </div>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      selecionar-apenas-um
      paginacao-em-memoria
      :mostra-header="false"
      mostrar-seletor-check-box
      :mostrar-seletor="false"
      sem-paginacao
      :por-pagina="tabela.porPagina"
      sort-field="revisao"
      :sort-order="-1"
      :pagina-atual="tabela.paginaAtual"
    >
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:seletor="{ slotProps }">
        <div class="d-flex justify-center">
          <v-simple-checkbox
            v-if="deveMostrarCheckbox(slotProps.data)"
            :value="slotProps.data.isSelected"
            @input="selecionarItem(slotProps.data)"
          />
        </div>
      </template>

      <!-- <template v-slot:item.data-table-select="item">
        <div>
          <v-simple-checkbox
            v-if="deveMostrarCheckbox(item)"
            :value="item.item.isSelected"
            @input="selecionarItem(item)"
          />
        </div>
      </template> -->

      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <v-list-item-group style="overflow-y: auto">
            <v-list-item
              class="d-flex min-height-drop-item"
              @click="abrirAcao(slotProps.data)"
            >
              {{ acaoLabel(slotProps.data) }}
            </v-list-item>
          </v-list-item-group>
        </dropdown-padrao>
      </template>

      <template v-slot:item.justificativa="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="text-overflow"
              v-on="on"
            >
              {{ item.value }}
            </div>
          </template>
          <span>{{ item.value }}</span>
        </v-tooltip>
      </template>
    </tabela-padrao-prime-vue>
    <v-row class="d-flex justify-end">
      <v-col cols="auto">
        <botao-padrao
          class="my-2"
          outlined
          color="black"
          @click="voltar"
        >
          <v-icon>$mdiArrowLeft</v-icon>
          {{ $t('geral.botoes.voltar') }}
        </botao-padrao>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import IncertezasService from '@common/services/cadastros/IncertezasService';
import helpers from '@common/utils/helpers';
import _ from 'lodash'

export default {
  components: { CabecalhoPagina },

  props: {
    id: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      codigo: '',
      tabela: {
        selecionados: [],
        dados: [],
        quantidadeItens: 0,
        quantidadePaginas: 1,
        paginaAtual: 1,
        porPagina: 10,
        colunas: [
          {
            text: this.$t('modulos.incertezas.revisoes.tabela.revisao'),
            sortable: true,
            value: 'revisao',
          },
          {
            text: this.$t('modulos.incertezas.revisoes.tabela.criado_em'),
            sortable: true,
            value: 'dataCriacao',
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, true); // true = hora
            },
          },
          {
            text: this.$t(
              'modulos.incertezas.revisoes.tabela.usuario_de_criacao'
            ),
            sortable: true,
            value: 'usuarioCriacao.nome',
          },
          {
            text: this.$t('modulos.incertezas.revisoes.tabela.publicada_em'),
            sortable: true,
            value: 'dataPublicacao',
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, true); // true = hora
            },
          },
          {
            text: this.$t(
              'modulos.incertezas.revisoes.tabela.usuario_de_publicacao'
            ),
            sortable: true,
            value: 'usuarioPublicacao.nome',
          },
          {
            value: 'justificativa',
            text: this.$t('modulos.incertezas.revisoes.tabela.justificativa'),
            sortable: true,
          },
        ],
      },
    };
  },

  computed: {
    tituloCabecalho: function () {
      return `${this.$t(
        'modulos.incertezas.titulos.visualizar_revisao_incertezas'
      )} ${this.codigo}`;
    },
  },

  mounted() {
    if (this.id) {
      this.listarRegistros();
    }
  },

  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    acaoLabel: function (item) {
      return item.dataPublicacao === null
        ? this.$t('geral.botoes.editar')
        : this.$t('geral.botoes.visualizar');
    },

    abrirAcao: function (item) {
      if (
        item.dataPublicacao === null &&
        this.buscarPermissao('Incerteza', 'Editar')
      ) {
        this.abrirEdicao(item);
      } else {
        this.abrirVisualização(item);
      }
    },
    excluirItem: function () {
      const { id: revisaoId } = this.tabela.selecionados[0];

      this.$store.dispatch('Layout/iniciarCarregamento');

      IncertezasService.excluirRevisao(this.id, revisaoId)
        .then(() => {
          this.toastSucesso(
            this.$t('modulos.incertezas.revisao_excluida_sucesso')
          );
          this.tabela.selecionados = [];
          this.listarRegistros();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    selecionarItem: function (item) {
      if (!item.isSelected) {
        item.isSelected = true;
        this.tabela.selecionados.push(item);
        console.log(item, "Add")
      } else {
        console.log(item, "Remove")
        item.isSelected = false;
        this.tabela.selecionados = this.tabela.selecionados.filter(
          ({ id }) => id !== item.id
        );
      }
      ++this.keyTable;
    },

    abrirEdicao: function (item) {
      this.$router.push({
        name: 'incertezas-revisoes-editar',
        params: { idRevisao: item.id },
      });
    },

    abrirVisualização: function (item) {
      this.$router.push({
        name: 'incertezas-revisoes-visualizar',
        params: { idRevisao: item.id },
      });
    },

    deveMostrarCheckbox: function (item) {
      return !item.dataPublicacao;
    },

    voltar: function () {
      this.$router.back();
    },

    listarRegistros: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');

      IncertezasService.listarRevisoes(this.id)
        .then((res) => {
          res.data.revisoes.forEach(revisao => revisao.isSelected = false)
          const dados = _.cloneDeep(res.data.revisoes);

          this.codigo = res.data.codigo;
          this.tabela.dados = dados;
          this.tabela.quantidadeItens = dados.length;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
